








import { Component, Vue } from 'vue-property-decorator'
import InfoCard from '@/general/components/common/InfoCard.vue'
import ECMR from '@/modules/shipments/views/detail/components/ECMR.vue'

@Component({
  components: {
    InfoCard,
    ECMR
  }
})
export default class CMRList extends Vue {
  ecmrs = Array.from(this.$store.state.transport.ecmrs.values()).flatMap(x => x)
}
